<template>
  <div class="route-container">
    <div style="display: flex; justify-content: space-between;">
      <div class="button-container">
        <b-button class="top-button" @click="toggleSubProjectPopup(false)" :disabled="anyPopupOpen">Unterprojekt erstellen</b-button>
        <b-button class="top-button" @click="toggleSubProjectPopup(true)" :disabled="!subProjectIsSelected || anyPopupOpen">Unterprojekt bearbeiten</b-button>
        <b-button class="top-button" @click="toggleOrderPopup('create')" :disabled="anyPopupOpen">Auftrag erstellen</b-button>
        <b-button class="top-button" @click="toggleOrderPopup('edit')" :disabled="!selectedOrder || anyPopupOpen">Auftrag bearbeiten</b-button>
        <b-button class="top-button" @click="toggleOrderPopup('copy')" :disabled="!selectedOrder || anyPopupOpen">Auftrag kopieren</b-button>
        <b-button class="top-button" @click="showImportPopup = true" >Import</b-button>
      </div>
    </div>
    <!-- Table -->
    <ag-grid-vue 
      class="app-table ag-theme-alpine"
      :columnDefs="columnDefs"
      :rowData="rowData"
      @grid-ready="onGridReady"
      rowSelection="single"
      :sideBar="sideBar"
      :masterDetail="true"
      :keepDetailRowsCount="1"
      :groupDefaultExpanded="0"
      @row-clicked="onRowClicked"
      :detailCellRendererParams="detailCellRendererParams"
      :getRowHeight="getRowHeight"
      :rowClassRules="rowClassRules"
    >
    </ag-grid-vue>

    <SubProjectEditPopup 
      v-if="showSubProjectPopup" 
      @close="closeSubProjectPopup(false)"
      @close-reload="closeSubProjectPopup(true)"
      :clientProjectList_treeselect="clientProjectList_treeselect"
      :edit="editSubProject"
      :selectedSubproject="selectedSubproject"
    ></SubProjectEditPopup>
    <OrderEditPopup 
      v-if="showOrderPopup" 
      @close="closeOrderPopup(false)"
      @close-reload="closeOrderPopup(true)"
      :clientProjectSubprojectList_treeselect="clientProjectSubprojectList_treeselect"
      :action="orderPopupAction"
      :selectedOrder="selectedOrder"
      :selectedSubproject="selectedSubproject"
      :budgetCategories="budgetCategories"
    ></OrderEditPopup>
    <OrderImport
      v-if="showImportPopup"
      @close="closeImportPopup(false)"
      @close-reload="closeImportPopup(true)"
    ></OrderImport>
  </div>
</template>

<script>
import { AgGridVue } from "ag-grid-vue";
import ControllingService from "../services/ControllingService";

import CurrencyCellRenderer from "./CellRenderer/CurrencyCellRenderer";
import DateCellRenderer from "./CellRenderer/DateCellRenderer";
import DateTimeCellRenderer from "./CellRenderer/DateTimeCellRenderer";
import MissingOrderYearCellRenderer from "./CellRenderer/MissingOrderYearCellRenderer.vue";
import PercentageCellRenderer from "./CellRenderer/PercentageCellRenderer";
import WeightedBudgetCellRenderer from "./CellRenderer/WeightedBudgetCellRenderer";

import OrderEditPopup from "./OrderEditPopup";
import OrderImport from "./OrderImport.vue";
import SubProjectEditPopup from "./SubProjectEditPopup";

export default {
  name: "OrderManagement",
  components: {
    AgGridVue,
    currencyCellRenderer: CurrencyCellRenderer,
    dateCellRenderer: DateCellRenderer,
    dateTimeCellRenderer: DateTimeCellRenderer,
    missingOrderYearCellRenderer: MissingOrderYearCellRenderer,
    OrderEditPopup,
    OrderImport,
    percentageCellRenderer:PercentageCellRenderer,
    SubProjectEditPopup,
    weightedBudgetCellRenderer: WeightedBudgetCellRenderer,
  },
  data() {
    return {
      budgetCategories: [],
      clientList: null,
      clientProjectList_treeselect: null,
      clientProjectSubprojectList_treeselect: null,
      detailCellRendererParams: null,
      detailGridInfo: null,
      editSubProject: false,
      getRowHeight: null,
      gridApi: null,
      gridColumnApi: null,
      isLoading: false,
      offerToSubProject: false,
      orderPopupAction: null,
      rowData: null,
      rowClassRules: {
        'old-row': params => (params.data?.Year ? params.data.Year : 9999) < new Date().getFullYear(),
      },
      selectedOrder: null,
      selectedSubproject: null,
      showImportPopup: false,
      showOfferPopup: false,
      showOrderPopup: false,
      showSubProjectPopup: false,
      sideBar: {
        toolPanels: [
          {
            id: 'columns',
            labelDefault: 'Spalten',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnFilter: false,
              suppressColumnSelectAll: false,
              suppressColumnExpandAll: false,
            },
          },
          {
            id: 'filters',
            labelDefault: 'Filter',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
            toolPanelParams: {
              suppressExpandAll: false,
              suppressFilterSearch: false,
            },
          },
        ],
      },
    }
  },
  created() {
    this.getRowHeight = (params) => {
      if (params.node?.detail && params.data?.Orders) {
        var offset = 80;
        var allDetailRowHeight =
          (params.data.Orders.length <= 10 ? params.data.Orders.length : 10) *
          params.api.getSizesForCurrentTheme().rowHeight;
        var gridSizes = params.api.getSizesForCurrentTheme();
        return (
          allDetailRowHeight +
          ((gridSizes && gridSizes.headerHeight) || 0) +
          offset
        );
      }
    };
  },
  beforeMount() {
    this.loadRowData();
    this.loadOptions({callback: () => {}});
  },
  mounted() {
    this.detailCellRendererParams = {
      // level 2 grid options
      detailGridOptions: {
        columnDefs: [
          { headerName: "Auftrag", field: "OrderName", sortable: true, filter: true, width:150, resizable: true },
          { headerName: "Kostenstelle", field: "FullCostCenterCode", sortable: true, filter: true, width: 130, resizable: true },
          { headerName: "Startdatum", field: "StartDate", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'dateCellRenderer' },
          { headerName: "Enddatum", field: "EndDate", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'dateCellRenderer' },
          { headerName: "Letzte Änderung", field: "Updated", sortable: true, filter: true, width:200, resizable: true, cellRenderer: 'dateTimeCellRenderer' },
          { headerName: "Erstellt von", field: "CreatedBy", sortable: true, filter: true, width:150, resizable: true },
          { headerName: "Budget", sortable: true, filter: true, width:150, resizable: true, cellRenderer: 'currencyCellRenderer',
            valueGetter: (params) => {
              // Access the first item in the 'OrderYears' array
              const firstItem = params.data.OrderYears[(params.data.OrderYears?.length - 1)];
              return firstItem ? firstItem.Budget : null;
            }, 
          },
          { headerName: "", field: "MissingOrderYears", sortable: true, filter: true, width:500, resizable: true, cellRenderer: "missingOrderYearCellRenderer" },
        ],
        rowSelection: 'single',
        onSelectionChanged: this.onSelectionChanged,
        rowClassRules: {
          'old-row': params => (params.data?.Year ? params.data.Year : 9999) < new Date().getFullYear()
        }
      },
      getDetailRowData: (params) => {
        params.successCallback(params.data.Orders);
      },
    };

    ControllingService.getBudgetCategories()
    .then(res => {
      this.budgetCategories = res.data;
      
      // unshift placeholder
      this.budgetCategories.unshift({ value: null, text: 'Kategorie hinzufügen...', disabled: true})
    });
  },
  computed: {
    anyPopupOpen() {
      return this.showOfferPopup || this.showOrderPopup || this.showSubProjectPopup;
    },
    columnDefs() {
      return [
        { headerName: "Kunde", field: "ClientName", sortable: true, filter: true, width:200, resizable: true },
        { headerName: "Projekt", field: "ProjectName", sortable: true, filter: true, width:250, resizable: true },
        { headerName: "Unterprojekt", field: "SubProjectName", sortable: true, filter: true, width:150, resizable: true },
        { headerName: "Wahrscheinlichkeit", field: "Probability", sortable: true, filter: true, resizable: true, cellRenderer: 'percentageCellRenderer' },
        { headerName: "CL", field: "Consultant", sortable: true, filter: true, width:70, resizable: true },
        { headerName: "Letzte Änderung", field: "Updated", sortable: true, filter: true, width:200, resizable: true, cellRenderer: 'dateTimeCellRenderer' },
        { headerName: "Erstellt von", field: "CreatedBy", sortable: true, filter: true, width:150, resizable: true },
        { headerName: "", field: "MissingOrderYears", sortable: true, filter: true, width:500, resizable: true, cellRenderer: "missingOrderYearCellRenderer" },
      ];
    },
    oldSubprojectSelected() {
      if(!this.selectedSubproject?.Year) return false;
      return this.selectedSubproject?.Year < new Date().getFullYear();
    },
    subProjectIsSelected() {
      return this.selectedSubproject != null;
    },
  },
  watch: {
    $route (to, from){
      if (this.$router.currentRoute.path == "/orderManagement") {
        this.loadRowData();
      }
    }
  },
  methods: {
    closeOfferPopup(reload) {
      this.showOfferPopup = false;
      if(reload) this.reload();
    },
    closeOrderPopup(reload) {
      this.showOrderPopup = false;
      if(reload) this.reload();
    },
    closeSubProjectPopup(reload) {
      this.showSubProjectPopup = false;
      if(reload) this.reload();
    },
    closeImportPopup(reload) {
      this.showImportPopup = false;
      if(reload) this.reload();
    },
    loadOptions({callback}){
      this.isLoading = true;
      ControllingService.getClientWithProjectAndSubProject()
      .then((res) => {
        this.clientList = res.data;
        this.clientProjectList_treeselect = res.data.map(item => {
          return { 
            id: item.ClientId, 
            label: item.ClientName, 
            clientCode: item.ClientCode,
            children: item?.Projects?.map(p => {
              return {id: p.ProjectId, label: p.ProjectName}
            }),
            isDisabled: (item?.Projects) ? false : true
          };
        });

        this.clientProjectSubprojectList_treeselect = res.data.map(item => {
          return { 
            id: `client-${item.ClientId}`, 
            label: item.ClientName, 
            children: item?.Projects?.map(p => {
              return {
                id: `project-${p.ProjectId}`, 
                label: p.ProjectName, 
                children: p?.SubProjects?.map(sp => {
                  return {
                    id: sp.SubProjectId, 
                    label: sp.SubProjectName,
                    isDisabled: (sp?.Probability >= 90) ? false : true
                  }
                }),
                isDisabled: (p?.SubProjects) ? false : true
              }
            }),
            isDisabled: (item?.Projects) ? false : true
          };
        });
      })
      .finally(() => {
        this.isLoading = false;
        callback();
      })
    },
    loadRowData() {
      if(this.gridApi) this.gridApi.showLoadingOverlay();

      ControllingService.getOrderManagement()
      .then(res => {
        this.rowData = [];
        if(res.data === '') return;
        this.rowData = res.data;
      })
      .finally(() => {
        if(this.selectedSubproject != null) {
          // reopen selected node
          this.gridApi.forEachNode( node => {
            if(node.data.SubProjectId === this.selectedSubproject.SubProjectId && node.data.Year === this.selectedSubproject.Year) {
              node.setExpanded(true);
              node.setSelected(true);
            } else {
              node.setExpanded(false);
              node.setSelected(false);
            }
          });
          this.gridApi.onGroupExpandedOrCollapsed();
          this.gridApi.forEachDetailGridInfo(detailGridInfo => {
            this.detailGridInfo = detailGridInfo;
          });
        };
        if(this.gridApi) this.gridApi.hideOverlay();
      });
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },
    onRowClicked() {
      // collapse other rows on selectionchange
      this.gridApi.forEachNode(function (node) {
        if(node.selected === true) {
          node.setExpanded(!node.expanded);
          node.setSelected(true);
        } else {
          node.setExpanded(false);
          node.setSelected(false);
        }
      });
      this.gridApi.onGroupExpandedOrCollapsed();
      this.selectedOrder = null;
      this.selectedSubproject = this.gridApi.getSelectedRows()[0];

      this.gridApi.forEachDetailGridInfo(detailGridInfo => {
        this.detailGridInfo =  detailGridInfo;
      });
    },
    onSelectionChanged() {
      this.selectedOrder = this.detailGridInfo.api.getSelectedRows()[0];
    },
    reload() {
      this.selectedOrder = null;
      this.loadRowData();
      this.loadOptions({callback: () => {}});
    },
    toggleOrderPopup(action) {
      this.orderPopupAction = action;
      this.showOrderPopup = !this.showOrderPopup;
    },
    toggleSubProjectPopup(edit) {
      this.editSubProject = edit;
      this.showSubProjectPopup = !this.showSubProjectPopup;
    },
  },
}
</script>

<style scoped>
.table-controls {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
</style>