<template>
  <b-card no-body v-draggable class="popup text-dark">
    <b-card-header
      style="height: 25px; user-select: none;"
      class="pl-2 pb-0 pt-0"
      header-tag="div"
    >
      <b-row class="mx-0 px-0">
        <b-col class="mx-0 px-0" cols="11">
          Auftrag {{action === 'edit' ? 'bearbeiten' : (action === 'create' ? 'erstellen' : 'kopieren')}}
          <font-awesome-icon 
            v-if="orderHistory"
            class="mx-2 clickable"
            icon="fa-solid fa-book"
            style="cursor:pointer;"
            @click="showHistory = !showHistory"
          />
        </b-col>
        <b-col class="mx-0 px-0" cols="1" style="text-align: right">
          <font-awesome-icon
            class="mx-1 clickable"
            icon="fa-solid fa-xmark"
            @click="$emit('close')"
            style="cursor:pointer;"
          />
        </b-col>
      </b-row>
    </b-card-header>

    <b-form id="order-edit-popup-form" @submit="onSubmit">
      <b-card-body id="order-edit-popup-card-body">
        <div v-if="showTransferPopup" style="position: absolute; width: 100%; height: 100%;"></div>
        <div class="toggle-collapse">
          <h5><b>Allgemeine Informationen</b></h5>
          <font-awesome-icon
            :class="'mx-1 clickable ' + (generalInformationVisible? '' : 'rotate180')"
            icon="fa-solid fa-angle-up"
            style="font-size: 20pt;"
            @click="(generalInformationVisible = !generalInformationVisible)"
          />
        </div>
        <b-collapse v-model="generalInformationVisible">
          <b-row>
            <b-col>
              <label for="input-project">Kunde/Projekt/Unterprojekt:</label>
              <treeselect 
                id="input-project"
                v-model="order.SubProjectId" 
                placeholder="Kunde/Projekt/Unterprojekt..."
                :options="clientProjectSubprojectList_treeselect"
                :loadOptions="null"
                :multiple="false"
                :disable-branch-nodes="true"
                :disabled="subProjectIdLocked"
                required
              />
            </b-col>
            <b-col>
              <!-- <b-form-group
                label="Consultant:"
                label-for="input-vl"
              >
                <b-form-input
                  id="input-vl"
                  v-model="order.Consultant"
                  type="text"
                  placeholder="Consultant..."
                  lazy-formatter
                  :formatter="formatterConsultant"
                ></b-form-input>
              </b-form-group> -->
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                label="Auftragsname:"
                label-for="input-name"
              >
                <b-form-input
                  id="input-name"
                  v-model="order.OrderName"
                  type="text"
                  placeholder="Auftragsname..."
                  :state="orderNameAvailable"
                  @blur="getOrderNameAvailable()"
                  required
                ></b-form-input>
                <b-form-invalid-feedback id="input-name">
                  Name bereits vergeben.
                </b-form-invalid-feedback>
              </b-form-group>
            </b-col>
            <b-col>
              <b-row>
                <b-col>
                  <b-form-group
                    label="Startdatum:"
                    label-for="input-startDate"
                  >
                    <b-form-input
                      id="input-startDate"
                      v-model="order.StartDate"
                      type="date"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group
                    label="Enddatum:"
                    label-for="input-endDate"
                  >
                    <b-form-input
                      id="input-endDate"
                      v-model="order.EndDate"
                      type="date"
                      :state="states.inputEndDate"
                      required
                    ></b-form-input>
                    <b-form-invalid-feedback id="input-endDate">
                      {{ inputEndDateInvalidMsg }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-collapse>
        <b-tabs v-model="tabIndex">
          <template v-for="(orderYear, index_orderYear) in order.OrderYears">
            <b-tab :title="String(orderYear.Year)" :key="index_orderYear">
              <b-button
                v-if="index_orderYear != 0"
                class="my-2 transfer-button" 
                variant="outline-secondary"
                size="sm"
                pill
                @click="showTransferPopup = !showTransferPopup"
              >
                <font-awesome-icon
                  icon="fa-solid fa-copy"
                  style="font-size: 12pt;"
                />
                <span class="ml-2 transfer-span">Daten aus Vorjahr übernehmen</span>
              </b-button>
              <div class="toggle-collapse mt-3">
                <h5><b>Nachweise</b></h5>
                <font-awesome-icon
                  :class="'mx-1 clickable ' + (uploadsVisible? '' : 'rotate180')"
                  icon="fa-solid fa-angle-up"
                  style="font-size: 20pt;" 
                  @click="(uploadsVisible = !uploadsVisible)"
                />
              </div>
              <b-collapse v-model="uploadsVisible">
                <br>
                <b-row>
                <!-- FILES -->
                  <template v-for="(upload, index_upload) in orderYear.Uploads">
                    <b-col v-bind:key="index_upload">
                      <b-form-group>
                        <div class="upload-button-container">
                          <font-awesome-icon
                            :class="'mx-1 ' + (upload.UploadId ? 'clickable ' : 'disabled-color ')"
                            icon="fa-solid fa-download"
                            @click="openUpload(upload.UploadId)"
                          />
                          <font-awesome-icon
                            :class="'mx-1 ' + ((upload.UploadFile || upload.Name) ? 'clickable ' : 'disabled-color ')"
                            icon="fa-solid fa-trash"
                            @click="removeUpload(upload.UploadId, index_orderYear, index_upload)"
                          />
                        </div>
                        <b-form-file
                          v-model="upload.UploadFile"
                          :placeholder="upload.Name || 'Keine Datei ausgewählt'"
                        ></b-form-file>
                      </b-form-group>
                    </b-col>
                  </template>
                </b-row>
              </b-collapse>
              <hr>
              <!-- budget data -->
              <div class="toggle-collapse">
                <h5><b>Budgetplanung</b></h5>
                <font-awesome-icon
                  :class="'mx-1 clickable ' + (budgetDataVisible? '' : 'rotate180')"
                  icon="fa-solid fa-angle-up"
                  style="font-size: 20pt;" 
                  @click="(budgetDataVisible = !budgetDataVisible)"
                />
              </div>
              <b-collapse v-model="budgetDataVisible">
                <b-row v-if="orderYear.BudgetCategories && orderYear.BudgetCategories.length != 0">
                  <b-col cols="2"></b-col>
                  <b-col cols="3">Kommentar</b-col>
                  <b-col cols="2">Anzahl</b-col>
                  <b-col cols="2">Preis (€)</b-col>
                  <b-col cols="2">Gesamt (€)</b-col>
                  <b-col cols="1"></b-col>
                </b-row>
                <template v-for="(budgetCategory, index_budgetCategory) in orderYear.BudgetCategories">
                  <b-row class="my-1">
                    <b-col cols="2">
                      <b>{{index_budgetCategory + 1}}. {{getBudgetCategoryText(budgetCategory.BudgetCategoryId)}}</b>
                    </b-col>
                    <b-col cols="3">
                      <b-form-input
                        :id="`input-budgetCategory-comment-${index_budgetCategory}`"
                        v-model="budgetCategory.Comment"
                        type="text"
                        placeholder="Kommentar..."
                      ></b-form-input>
                    </b-col>
                    <b-col cols="2">
                      <b-form-input
                        :id="`input-budgetCategory-amount-${index_budgetCategory}`"
                        v-model="budgetCategory.Amount"
                        type="number"
                        placeholder="Anzahl..."
                        :step="1"
                        :min="0"
                        @change="calculateBudget(orderYear), calculateSurveyInstancePriceMdatiqs(orderYear)"
                      ></b-form-input>
                    </b-col>
                    <b-col cols="2">
                      <span style="position: absolute; transform: translate(-20px, 4px);">×</span>
                      <b-form-input
                        :id="`input-budgetCategory-cost-${index_budgetCategory}`"
                        v-model="budgetCategory.Cost"
                        type="number"
                        placeholder="Preis..."
                        :step="0.01"
                        :min="0"
                        @change="calculateBudget(orderYear), calculateSurveyInstancePriceMdatiqs(orderYear)"
                      ></b-form-input>
                    </b-col>
                    <b-col cols="2">
                      <span style="position: absolute; transform: translate(-20px, 4px);">=</span>
                      <b-form-input
                        :id="`input-budgetCategory-result-${index_budgetCategory}`"
                        type="number"
                        placeholder="..."
                        :step="0.01"
                        :min="0"
                        :value="(budgetCategory.Amount * budgetCategory.Cost).toFixed(2)"
                        disabled
                      ></b-form-input>
                    </b-col>
                    <b-col cols="1" style="justify-content: center; display: flex;">
                      <b-button class="mr-2" variant="outline-danger" @click="deleteBudgetCategory(budgetCategory, orderYear)">
                        <font-awesome-icon
                          class="mx-1 clickable"
                          icon="fa-solid fa-trash"
                          style="font-size: 14pt;"
                        />
                      </b-button>
                    </b-col>
                  </b-row>
                </template>
                <b-row>
                  <b-col cols="3" style="display: flex; justify-content: flex-start; align-items: center">
                    <font-awesome-icon
                      :class="'mx-2 clickable '"
                      icon="fa-solid fa-plus"
                      style="font-size: 20pt;" 
                      @click="addBudgetCategory(orderYear)"
                    />
                    <b-form-select 
                      style="width: auto"
                      v-model="selectedBudgetCategoryId" 
                      :options="budgetCategories" 
                    ></b-form-select>
                  </b-col>
                  <b-col cols="2"></b-col>
                  <b-col cols="2"></b-col>
                  <b-col cols="2"></b-col>
                  <b-col cols="2">
                    <hr v-if="orderYear.BudgetCategories && orderYear.BudgetCategories.length != 0">
                    <span style="position: absolute; transform: translate(-20px, 38px);">=</span>
                    <b-form-group
                      label="Budget (€):"
                      label-for="input-budget"
                    >
                      <b-form-input
                        id="input-budget"
                        v-model="orderYear.Budget"
                        type="number"
                        placeholder="Budget..."
                        :step="0.01"
                        :min="0"
                        disabled
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col cols="1"></b-col>
                </b-row>
                <br>
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Rückstellungen (€):"
                      label-for="input-reserve"
                    >
                      <b-form-input
                        id="input-reserve"
                        v-model="orderYear.Reserve"
                        type="number"
                        placeholder="Rückstellungen..."
                        :step="0.01"
                        :min="0"
                        @change="calculateOverheadCosts(orderYear), calculateMarketingCosts(orderYear)"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Gemeinkosten-/Gewinnaufschlag (%):"
                      label-for="input-overheadcharge"
                    >
                      <b-form-input
                        id="input-overheadcharge"
                        v-model="orderYear.OverheadCharge"
                        type="number"
                        placeholder="Gemeinkosten- und Gewinnaufschlag..."
                        :step="1"
                        :min="0"
                        :max="100"
                        @change="calculateOverheadCosts(orderYear)"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Gemeinkosten (€):"
                      label-for="input-overheadCosts"
                    >
                      <b-form-input
                        id="input-overheadCosts"
                        v-model="orderYear.OverheadCosts"
                        type="number"
                        placeholder="Gemeinkosten..."
                        readonly
                        :step="0.01"
                        :min="0"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Vertriebsaufschlag (%):"
                      label-for="input-marketingcharge"
                    >
                      <b-form-input
                        id="input-marketingcharge"
                        v-model="orderYear.MarketingCharge"
                        type="number"
                        placeholder="Vertriebsaufschlag..."
                        :step="1"
                        :min="0"
                        :max="100"
                        @change="calculateMarketingCosts(orderYear)"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Vertriebskosten (€):"
                      label-for="input-marketingCosts"
                    >
                      <b-form-input
                        id="input-marketingCosts"
                        v-model="orderYear.MarketingCosts"
                        type="number"
                        placeholder="Gemeinkosten..."
                        readonly
                        :step="0.01"
                        :min="0"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-collapse>
              <hr>
              <div class="toggle-collapse">
                <h5><b>Fremdkosten</b></h5>
                <font-awesome-icon
                  :class="'mx-1 clickable ' + (externalCostsVisible? '' : 'rotate180')"
                  icon="fa-solid fa-angle-up"
                  style="font-size: 20pt;" 
                  @click="(externalCostsVisible = !externalCostsVisible)"
                />
              </div>
              <b-collapse v-model="externalCostsVisible">
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Fremdkosten MSM TK (€):"
                      label-for="input-externalCostsMsmTk"
                    >
                      <b-form-input
                        id="input-externalCostsMsmTk"
                        v-model="orderYear.ExternalCostsMsmTk"
                        type="number"
                        placeholder="Fremdkosten MSM TK..."
                        :step="0.01"
                        :min="0"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Fremdkosten Agentur (€):"
                      label-for="input-externalCostsAgency"
                    >
                      <b-form-input
                        id="input-externalCostsAgency"
                        v-model="orderYear.ExternalCostsAgency"
                        type="number"
                        placeholder="Fremdkosten Agentur..."
                        :step="0.01"
                        :min="0"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Fremdkosten BOLD (€):"
                      label-for="input-externalCostsMdatiqs"
                    >
                      <b-form-input
                        id="input-externalCostsMdatiqs"
                        v-model="orderYear.ExternalCostsMdatiqs"
                        type="number"
                        placeholder="Fremdkosten BOLD..."
                        :step="0.01"
                        :min="0"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Fremdkosten Sonstige (€):"
                      label-for="input-externalCostsOther"
                    >
                      <b-form-input
                        id="input-externalCostsOther"
                        v-model="orderYear.ExternalCostsOther"
                        type="number"
                        placeholder="Fremdkosten Sonstige..."
                        :step="0.01"
                        :min="0"
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Checkpreis BOLD 2€ pro Check (€):"
                      label-for="input-surveyInstancePriceMdatiqs"
                    >
                      <b-form-input
                        id="input-surveyInstancePriceMdatiqs"
                        v-model="orderYear.SurveyInstancePriceMdatiqs"
                        type="number"
                        placeholder="Checkpreis BOLD..."
                        :step="1"
                        :min="0"
                        disabled
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-collapse>
              <hr>
              <div class="toggle-collapse">
                <h5><b>Abteilungsbudgets</b></h5>
                <font-awesome-icon
                  :class="'mx-1 clickable ' + (departmentBudgetsVisible? '' : 'rotate180')"
                  icon="fa-solid fa-angle-up"
                  style="font-size: 20pt;" 
                  @click="(departmentBudgetsVisible = !departmentBudgetsVisible)"
                />
              </div>
              <b-collapse v-model="departmentBudgetsVisible">
                <b-row>
                  <b-col>
                    <p>Verfügbares Abteilungsbudget: <b :class="(departmentBudget >= 0 ? 'black' : 'red')">{{ formatMoneyValue(departmentBudget) }}€</b></p> 
                    <p>Verteiltes Abteilungsbudget: <b :class="(distributedDepartmentBudget <= 100 ? 'black' : 'red')">{{ distributedDepartmentBudget }}%</b></p>
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Budget Feld (%):"
                      label-for="input-budgetField_percentage"
                    >
                      <b-form-input
                        id="input-budgetField_percentage"
                        v-model="orderYear.BudgetField_percentage"
                        type="number"
                        placeholder="Budget Feld..."
                        :step="1"
                        :max="100"
                        :min="0"
                        @change="calculateDepartmentBudgets(orderYear)"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Budget Feld (€):"
                      label-for="input-budgetField"
                    >
                      <b-form-input
                        id="input-budgetField"
                        v-model="orderYear.BudgetField"
                        type="number"
                        placeholder="Budget Feld..."
                        :step="0.01"
                        :min="0"
                        disabled
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Budget Projektmanagement (%):"
                      label-for="input-budgetProjectManagement_percentage"
                    >
                      <b-form-input
                        id="input-budgetProjectManagement_percentage"
                        v-model="orderYear.BudgetProjectManagement_percentage"
                        type="number"
                        placeholder="Budget Projektmanagement..."
                        :step="1"
                        :max="100"
                        :min="0"
                        @change="calculateDepartmentBudgets(orderYear)"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Budget Projektmanagement (€):"
                      label-for="input-budgetProjectManagement"
                    >
                      <b-form-input
                        id="input-budgetProjectManagement"
                        v-model="orderYear.BudgetProjectManagement"
                        type="number"
                        placeholder="Budget Projektmanagement..."
                        :step="0.01"
                        :min="0"
                        disabled
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Budget Qualitätssicherung (%):"
                      label-for="input-budgetQualityAssurance_percentage"
                    >
                      <b-form-input
                        id="input-budgetQualityAssurance_percentage"
                        v-model="orderYear.BudgetQualityAssurance_percentage"
                        type="number"
                        placeholder="Budget Qualitätssicherung..."
                        :step="1"
                        :max="100"
                        :min="0"
                        @change="calculateDepartmentBudgets(orderYear)"
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Budget Qualitätssicherung (€):"
                      label-for="input-budgetQualityAssurance"
                    >
                      <b-form-input
                        id="input-budgetQualityAssurance"
                        v-model="orderYear.BudgetQualityAssurance"
                        type="number"
                        placeholder="Budget Qualitätssicherung..."
                        :step="0.01"
                        :min="0"
                        disabled
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-collapse>
              <hr>
              <div class="toggle-collapse">
                <h5><b>Abweichende Rechnungsadresse Auftrag</b></h5>
                <font-awesome-icon
                  :class="'mx-1 clickable ' + (billToVisible? '' : 'rotate180')"
                  icon="fa-solid fa-angle-up"
                  style="font-size: 20pt;" 
                  @click="(billToVisible = !billToVisible)"
                />
              </div>
              <b-collapse v-model="billToVisible">
                <b-row>
                  <b-col>
                    <b-form-group
                      label="Rechnungsempfänger:"
                      label-for="input-billTo"
                    >
                      <b-form-input
                        id="input-billTo"
                        v-model="orderYear.BillTo"
                        type="text"
                        placeholder="Rechnungsempfänger..."
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Adresse:"
                      label-for="input-billingAddress"
                    >
                      <b-form-input
                        id="input-billingAddress"
                        v-model="orderYear.BillingAddress"
                        type="text"
                        placeholder="Adresse..."
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="Ort:"
                      label-for="input-billingCity"
                    >
                      <b-form-input
                        id="input-billingCity"
                        v-model="orderYear.BillingCity"
                        type="text"
                        placeholder="Ort..."
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                  <b-col>
                    <b-form-group
                      label="Land:"
                      label-for="input-billingCountry"
                    >
                      <b-form-select
                        id="input-billingCountry"
                        v-model="orderYear.BillingCountry"
                        :options="countryList"
                        placeholder="Land..."
                      ></b-form-select>
                    </b-form-group>
                    <b-form-group
                      label="Postleitzahl:"
                      label-for="input-billingPostalCode"
                    >
                      <b-form-input
                        id="input-billingPostalCode"
                        v-model="orderYear.BillingPostalCode"
                        type="text"
                        placeholder="Postleitzahl..."
                      ></b-form-input>
                    </b-form-group>
                    <b-form-group
                      label="E-Mail-Adresse:"
                      label-for="input-email"
                    >
                      <b-form-input
                        id="input-email"
                        v-model="orderYear.BillingEmail"
                        type="email"
                        placeholder="E-Mail-Adresse..."
                      ></b-form-input>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-collapse>
            </b-tab>
          </template>
        </b-tabs>
      </b-card-body>
      <b-card-footer id="order-edit-popup-card-footer">
        <b-row>
          <b-col v-if="order.OrderYears[tabIndex]" cols="9" style="display: flex; align-items: center; font-size: 14pt; font-weight: bold;">
            <b-button class="mr-2" variant="outline-danger" @click="showDeletePopup = true" v-if="action === 'edit'">
              <font-awesome-icon
                class="mx-1 clickable"
                icon="fa-solid fa-trash"
                style="font-size: 14pt;"
              />
              <span>Löschen</span>
            </b-button>
            <span class="mx-2">Budget nach Auftrag: {{formatMoneyValue(order.OrderYears[tabIndex].Budget)}}€</span>
            <span class="mx-2">Verplantes Budget: {{formatMoneyValue(usedBudget)}}€</span>
            <span class="mx-2">Differenz: <span :class="((order.OrderYears[tabIndex]?.Budget - usedBudget) >= 0 ? 'green' : 'red')">{{formatMoneyValue(order.OrderYears[tabIndex]?.Budget - usedBudget)}}€</span></span>
          </b-col>
          <b-col style="text-align:end">
            <b-button class="m-2" type="submit" variant="primary" :disabled="formChanges === 0 || orderNameAvailable === false">Speichern</b-button>
            <b-button class="m-2" @click="$emit('close')" variant="secondary">Abbrechen</b-button>
          </b-col>
        </b-row>
      </b-card-footer>
    </b-form>

    <!-- History Popup -->
    <GenericPopup v-if="showHistory" @close="showHistory = false" width="50vw" max-height="50vh">
      <div v-for="(e, index) in orderHistory" :key="index">
        {{e.FirstName}} {{e.LastName}} {{e.Timestamp}}: 
        <br>
        <span v-html="displayHistoryElement(e.OrderJson, index)"></span>
        <hr>
      </div>
    </GenericPopup>

    <!-- Delete Popup -->
    <GenericPopup v-if="showDeletePopup" @close="showDeletePopup = false" width="25vw">
      <b-col>
        <b-row style="display: flex;justify-content: center;text-align: center;">
          <span v-if="!order.HasSalary">Soll das Unterprojekt gelöscht werden?</span>
          <span v-else-if="order.HasSalary">
            Es gibt Zeiteinträge für diesen Auftrag.
            <br> Er kann nicht gelöscht werden.
          </span>
        </b-row>
        <b-row style="display: flex;justify-content: center; align-items:center">
          <font-awesome-icon 
            v-if="order.HasSalary"
            class="mx-2"
            icon="fa-solid fa-lock"
          />
          <b-button class="m-2" variant="danger" @click="setOrderInactive()" :disabled="order.HasSalary">Löschen</b-button>
          <b-button class="m-2" @click="showDeletePopup = false" >Abbrechen</b-button>
        </b-row>
      </b-col>
    </GenericPopup>

    <!-- Transfer Popup -->
    <GenericPopup v-if="showTransferPopup" @close="showTransferPopup = false" width="20vw">
      <b-col>
        <b-row>Daten aus {{order.OrderYears[this.tabIndex - 1].Year}} in {{order.OrderYears[this.tabIndex].Year}} übernehmen:</b-row>
        <b-row class="mt-2">
          <b-col>
            <template v-for="(option, index) in transferOptions">
              <b-form-checkbox 
                v-bind:key="index"
                v-model="option.value"
              >{{option.name}}</b-form-checkbox>
            </template>
          </b-col>
        </b-row>
        <b-row class="mt-2" style="display: flex;justify-content: center;">
          <b-button class="m-2" variant="danger" @click="transferFromPreviousYear()">Übernehmen</b-button>
          <b-button class="m-2" @click="showTransferPopup = false" >Abbrechen</b-button>
        </b-row>
      </b-col>
    </GenericPopup>
  </b-card>
</template>

<script>
import AuthenticationService from '../services/AuthenticationService';
import ControllingService from '../services/ControllingService';
import UploadService from '../services/UploadService';

import Treeselect from '@riophae/vue-treeselect';
import GenericPopup from './GenericPopup';

const UPLOAD_TEMPLATE = {
  Name: null,
  UploadFile: null,
  DataURI: null
};
const ORDER_YEAR_TEMPLATE = {
  "Year":null,
  "OrderId":null,
  "Reserve":0,
  "PlannedSurveyInstanceNumber":0,
  "SurveyInstancePrice":0,
  "Budget":0,
  "OverheadCharge":0,
  "OverheadCosts":0,
  "MarketingCharge":0,
  "MarketingCosts":0,
  "ExternalCostsMsmTk":0,
  "ExternalCostsAgency":0,
  "ExternalCostsOther":0,
  "ExternalCostsMdatiqs":0,
  "SurveyInstancePriceMdatiqs":0,
  "BudgetField":0,
  "BudgetField_percentage":0,
  "BudgetProjectManagement":0,
  "BudgetProjectManagement_percentage":null,
  "BudgetQualityAssurance":0,
  "BudgetQualityAssurance_percentage":null,
  "BillTo":null,
  "BillingAddress":null,
  "BillingCity":null,
  "BillingCountry":null,
  "BillingPostalCode":null,
  "BillingEmail":null,
  "ThirdPartyServices":null,
  "AllocationOfOverheadCosts":null,
  "Uploads": []
};

export default {
  name: "OrderEditPopup",
  components: {
    Treeselect,
    GenericPopup
  },
  props: {
    clientProjectSubprojectList_treeselect: {},
    action: null,
    selectedOrder: {},
    selectedSubproject: {},
    budgetCategories: [],
  },
  data() {
    return {
      budgetDataVisible: true,
      countryList: null,
      formChanges: 0,
      generalInformationVisible: true,
      inputEndDateInvalidMsg: null,
      order: {
        "OrderId": null,
        "SubProjectId": null,
        "OrderName": null,
        "StartDate": null,
        "EndDate": null,
        "OrderYears": []
      },
      orderHistory: null,
      orderNameAvailable: null,
      showDeletePopup: false,
      showHistory: false,
      showTransferPopup: false,
      subProjectIdLocked: false,
      states: {
        inputEndDate: null,
      },
      tabIndex: 0,
      transferOptions: {
        files: {
          name: "Nachweise",
          value: false
        },
        budget: {
          name: "Budgetplanung",
          value: false
        },
        costs: {
          name: "Fremdkosten",
          value: false
        },
        department: {
          name: "Abteilungsbudgets",
          value: false
        },
        address: {
          name: "Abweichende Rechnungsadresse Auftrag",
          value: false
        },
      },
      uploadsToBeDeleted: [],

      billToVisible: false,
      departmentBudgetsVisible: true,
      externalCostsVisible: true,
      uploadsVisible: true,
      selectedBudgetCategoryId: null
    }
  },
  watch: {
    order: {
      handler() {
        this.formChanges++;
      },
      deep: true
    },
    'order.StartDate'() {
      this.applyOrderYears();
    },
    'order.EndDate'() {
      this.applyOrderYears();
    },
  },
  computed: {
    usedBudget() {
      if(!this.order.OrderYears[this.tabIndex]) return null;
      var res = (
          (this.order.OrderYears[this.tabIndex].Reserve ? Number(this.order.OrderYears[this.tabIndex].Reserve) : 0)
        + (this.order.OrderYears[this.tabIndex].OverheadCosts ? Number(this.order.OrderYears[this.tabIndex].OverheadCosts) : 0)
        + (this.order.OrderYears[this.tabIndex].MarketingCosts ? Number(this.order.OrderYears[this.tabIndex].MarketingCosts) : 0)
        + (this.order.OrderYears[this.tabIndex].ExternalCostsMsmTk ? Number(this.order.OrderYears[this.tabIndex].ExternalCostsMsmTk) : 0)
        + (this.order.OrderYears[this.tabIndex].ExternalCostsAgency ? Number(this.order.OrderYears[this.tabIndex].ExternalCostsAgency) : 0)
        + (this.order.OrderYears[this.tabIndex].ExternalCostsOther ? Number(this.order.OrderYears[this.tabIndex].ExternalCostsOther) : 0)
        + (this.order.OrderYears[this.tabIndex].ExternalCostsMdatiqs ? Number(this.order.OrderYears[this.tabIndex].ExternalCostsMdatiqs) : 0)
        + (this.order.OrderYears[this.tabIndex].SurveyInstancePriceMdatiqs ? Number(this.order.OrderYears[this.tabIndex].SurveyInstancePriceMdatiqs) : 0)
        + (this.order.OrderYears[this.tabIndex].BudgetField ? Number(this.order.OrderYears[this.tabIndex].BudgetField) : 0)
        + (this.order.OrderYears[this.tabIndex].BudgetProjectManagement ? Number(this.order.OrderYears[this.tabIndex].BudgetProjectManagement) : 0)
        + (this.order.OrderYears[this.tabIndex].BudgetQualityAssurance ? Number(this.order.OrderYears[this.tabIndex].BudgetQualityAssurance) : 0)
      )
      return res;
    },
    departmentBudget() {
      if(!this.order.OrderYears[this.tabIndex]) return null;
      var res = (
        (this.order.OrderYears[this.tabIndex].Budget ? Number(this.order.OrderYears[this.tabIndex].Budget) : 0)
        - (this.order.OrderYears[this.tabIndex].Reserve ? Number(this.order.OrderYears[this.tabIndex].Reserve) : 0)
        - (this.order.OrderYears[this.tabIndex].OverheadCosts ? Number(this.order.OrderYears[this.tabIndex].OverheadCosts) : 0)
        - (this.order.OrderYears[this.tabIndex].MarketingCosts ? Number(this.order.OrderYears[this.tabIndex].MarketingCosts) : 0)
        - (this.order.OrderYears[this.tabIndex].ExternalCostsMsmTk ? Number(this.order.OrderYears[this.tabIndex].ExternalCostsMsmTk) : 0)
        - (this.order.OrderYears[this.tabIndex].ExternalCostsAgency ? Number(this.order.OrderYears[this.tabIndex].ExternalCostsAgency) : 0)
        - (this.order.OrderYears[this.tabIndex].ExternalCostsOther ? Number(this.order.OrderYears[this.tabIndex].ExternalCostsOther) : 0)
        - (this.order.OrderYears[this.tabIndex].ExternalCostsMdatiqs ? Number(this.order.OrderYears[this.tabIndex].ExternalCostsMdatiqs) : 0)
        - (this.order.OrderYears[this.tabIndex].SurveyInstancePriceMdatiqs ? Number(this.order.OrderYears[this.tabIndex].SurveyInstancePriceMdatiqs) : 0)
      )
      return res;
    },
    distributedDepartmentBudget() {
      if(!this.order.OrderYears[this.tabIndex]) return null;
      return (
        parseInt(this.order.OrderYears[this.tabIndex].BudgetField_percentage ? this.order.OrderYears[this.tabIndex].BudgetField_percentage : 0) + 
        parseInt(this.order.OrderYears[this.tabIndex].BudgetProjectManagement_percentage ? this.order.OrderYears[this.tabIndex].BudgetProjectManagement_percentage : 0) + 
        parseInt(this.order.OrderYears[this.tabIndex].BudgetQualityAssurance_percentage ? this.order.OrderYears[this.tabIndex].BudgetQualityAssurance_percentage : 0)
      );
    },
  }, 
  mounted() {
    ControllingService.getCountryList()
    .then(res => {
      this.countryList = res.data;
      
      // unshift placeholder
      this.countryList.unshift({ value: null, text: 'Land...', disabled: true})
    });

    // set subproject if probability is 100
    if(this.selectedSubproject?.Probability === 100) {
      this.order.SubProjectId = this.selectedSubproject.SubProjectId;
    }

    // set default dates
    this.order.StartDate = new Date().getFullYear() + '-01-01';
    this.order.EndDate = new Date().getFullYear() + '-12-31';
    
    if(this.selectedOrder && this.action === 'edit'){
      this.order = JSON.parse(JSON.stringify(this.selectedOrder));

      this.order.OrderYears.forEach(orderYear => {
        if(!orderYear.Uploads) orderYear.Uploads = [];
        let index = orderYear.Uploads.length;
        for (index; index < 3; index++) {
          orderYear.Uploads.push(JSON.parse(JSON.stringify(UPLOAD_TEMPLATE)))
        }
      });

      if(this.order.SubProjectId) {
        this.subProjectIdLocked = true;
      }

      // get Order History
      if(this.hasPermission('Management')) {
        ControllingService.getOrderHistory(this.selectedOrder.OrderId)
        .then(res => {
          if(res.data) {
            this.orderHistory = res.data;
          }
        });
      }
    };

    if(this.selectedOrder && this.action === 'copy'){
      this.order.SubProjectId = this.selectedOrder.SubProjectId
      this.order.OrderName = (this.selectedOrder.OrderName + ' - KOPIE');
      this.order.StartDate = this.selectedOrder.StartDate
      this.order.EndDate = this.selectedOrder.EndDate
      this.order.OrderYears = this.selectedOrder.OrderYears
      this.order.OrderYears.forEach(oy => {
        oy.Uploads = [];
        for (let index = 0; index < 3; index++) {
          oy.Uploads.push(JSON.parse(JSON.stringify(UPLOAD_TEMPLATE)))
        }
      });

      if(this.order.SubProjectId) {
        this.subProjectIdLocked = true;
      }
    };

    // wait for all to be mounted
    setTimeout(() => {
      this.tabIndex = this.order.OrderYears?.length - 1;

      this.formChanges = 0;
      if(this.action === 'copy') this.formChanges++;
    }, 100);
  },
  methods: {
    addBudgetCategory(orderYear) {
      if(!this.selectedBudgetCategoryId) return;
      let cat = this.budgetCategories.find(e => e.value === this.selectedBudgetCategoryId);
      if(!orderYear.BudgetCategories) orderYear.BudgetCategories = [];
      orderYear.BudgetCategories.push({Amount: 1, BudgetCategoryId: cat.value, Cost: 0});
      this.selectedBudgetCategoryId = null;

      this.calculateBudget(orderYear);
      this.calculateSurveyInstancePriceMdatiqs(orderYear);
    },
    applyOrderYears() {
      if (!this.order.StartDate || !this.order.EndDate) {
        return;
      }
      const years = [];
      const startYear = new Date(Date.parse(this.order.StartDate)).getFullYear();
      const endYear = new Date(Date.parse(this.order.EndDate)).getFullYear();

      for (let year = startYear; year <= endYear; year++) {
          years.push(year);
      }
      years.forEach(year => {
        var hasYear = false;
        for (let index = 0; index < this.order.OrderYears.length; index++) {
          const orderYear = this.order.OrderYears[index];
          if(orderYear?.Year == year) {
            hasYear = true;
          }
        };
        if(!hasYear) {
          var newOrderYear = JSON.parse(JSON.stringify(ORDER_YEAR_TEMPLATE));
          newOrderYear.Year = year;
          for (let index = 0; index < 3; index++) {
            newOrderYear.Uploads.push(JSON.parse(JSON.stringify(UPLOAD_TEMPLATE)))
          }
          this.order.OrderYears.push(newOrderYear)
        }
      });

      // delete orderYears when not in new year range
      for (let index = 0; index < this.order.OrderYears.length; index++) {
        const orderYear = this.order.OrderYears[index];
        if(!years.includes(orderYear.Year)) {
          delete this.order.OrderYears[index];
        }
      }
      this.order.OrderYears = this.order.OrderYears.filter(oy => oy != undefined );
    },
    calculateBudget(orderYear) {
      let budget = 0;
      if(orderYear.BudgetCategories) {
        orderYear.BudgetCategories.forEach(cat => {
          budget += parseFloat(((cat.Cost ? cat.Cost : 0 ) * (cat.Amount ? cat.Amount : 0)).toFixed(2));
        });
      }
      orderYear.Budget = budget.toFixed(2);

      this.calculateOverheadCosts(orderYear);
      this.calculateMarketingCosts(orderYear);
    },
    calculateDepartmentBudgets(orderYear) {
      orderYear.BudgetField = (this.departmentBudget * (orderYear.BudgetField_percentage / 100)).toFixed(2);
      orderYear.BudgetProjectManagement = (this.departmentBudget * (orderYear.BudgetProjectManagement_percentage / 100)).toFixed(2);
      orderYear.BudgetQualityAssurance = (this.departmentBudget * (orderYear.BudgetQualityAssurance_percentage / 100)).toFixed(2);
    },
    calculateMarketingCosts(orderYear) {
      orderYear.MarketingCosts = ((orderYear?.Budget - orderYear?.Reserve) * (orderYear?.MarketingCharge / 100)).toFixed(2);
    },
    calculateOverheadCosts(orderYear) {
      orderYear.OverheadCosts = ((orderYear?.Budget - orderYear?.Reserve) * (orderYear?.OverheadCharge / 100)).toFixed(2);
    },
    calculateSurveyInstancePriceMdatiqs(orderYear) {
      let budgetCategory_Checks_Id = this.budgetCategories.find(e => e.text === "Checks")?.value;
      if(!budgetCategory_Checks_Id) return;
      let plannedSurveyInstanceNumber = 0;
      if(orderYear.BudgetCategories) {
        orderYear.BudgetCategories.forEach(cat => {
          if(cat.BudgetCategoryId === budgetCategory_Checks_Id) plannedSurveyInstanceNumber += parseFloat(cat.Amount);
        });
      };

      orderYear.SurveyInstancePriceMdatiqs = plannedSurveyInstanceNumber * 2;
    },
    deleteBudgetCategory(budgetCategory, orderYear) {
      orderYear.BudgetCategories = orderYear.BudgetCategories.filter(cat => cat != budgetCategory);
      this.calculateBudget(orderYear);
      this.formChanges++;
    },
    async deleteUploads() {
      const deletePromises = this.uploadsToBeDeleted.map(uploadId => {
        return UploadService.deleteUpload(uploadId);
      });

      await Promise.all(deletePromises);
    },
    displayHistoryElement(OrderJson, index){
      if(index == this.orderHistory.length - 1) return this.formatJson(OrderJson);
      var historyJsonBefore = this.orderHistory[index + 1].OrderJson;
      var newJson = this.findJsonDifferences(historyJsonBefore, OrderJson);
      return this.formatJson(newJson);
    },
    formatJson(jsonString) {
      var formattedJson = JSON.stringify(jsonString, null, 4);
      return formattedJson.replace(/ /g, '&nbsp;').replace(/\n/g, '<br>');
    },
    formatMoneyValue(value) {
      if(!value) return 0;
      var isNegative = false;
      var output = null;
      var indexOfPoint = value.toString().indexOf('.');
      var number;
      var res;

      if(indexOfPoint != -1) {
        number = value.toString().substring(0, indexOfPoint);
      } else {
        number = value.toString();
      }
      if(number < 0) {
        isNegative = true;
        number = number * (-1);
      }
      if (number.length > 3) {
        var mod = number.length % 3;
        var output = (mod > 0 ? (number.substring(0,mod)) : '');
        for (var i = 0 ; i < Math.floor(number.length / 3); i++) {
          if ((mod == 0) && (i == 0))
            output += number.substring(mod + 3 * i, mod + 3 * i + 3);
          else
            output += '.' + number.substring(mod + 3 * i, mod + 3 * i + 3);
        }
        res = output;
      }
      else {
        res = number;
      }
      // append decimals
      if(indexOfPoint != -1) {
        var decimals = value.toString().substring(indexOfPoint);
        res = res + ',' + decimals.substring(1,3);
        if(decimals.length < 3) {
          res = res + '0'
        }
      } else {
        res = res + ',00';
      }
      if(isNegative) {
        res = '-' + res;
      }
      return res;
    },
    findJsonDifferences(oldJson, newJson) {
      try {
        const diffJson = {};

        for (const key in newJson) {
          if (key === 'OrderYears') {
            // Always include the "OrderYears" array, even if there are no differences
            diffJson[key] = newJson[key].map((yearObj, index) => {
              const oldYearObj = oldJson[key] && oldJson[key][index];

              const yearDiff = {
                'Year': yearObj['Year'],
              };

              for (const subKey in yearObj) {
                if(!oldYearObj) {
                  yearDiff[subKey] = yearObj[subKey];
                } else if (yearObj[subKey] !== oldYearObj[subKey]) {
                  yearDiff[subKey] = yearObj[subKey];
                }
              }

              return Object.keys(yearDiff).length > 1 ? yearDiff : { 'Year': yearObj['Year'] };
            });
          } else if (typeof newJson[key] === 'object' && !Array.isArray(newJson[key])) {
            if (oldJson[key]) {
              const nestedDiff = this.findJsonDifferences(oldJson[key], newJson[key]);
              if (Object.keys(nestedDiff).length > 0) {
                diffJson[key] = nestedDiff;
              }
            } else {
              diffJson[key] = newJson[key];
            }
          } else if (Array.isArray(newJson[key])) {
            if (oldJson[key] && Array.isArray(oldJson[key]) && oldJson[key].length === newJson[key].length) {
              const arrayDiff = [];
              for (let i = 0; i < newJson[key].length; i++) {
                const nestedDiff = this.findJsonDifferences(oldJson[key][i], newJson[key][i]);
                if (Object.keys(nestedDiff).length > 0) {
                  arrayDiff.push(nestedDiff);
                }
              }
              if (arrayDiff.length > 0) {
                diffJson[key] = arrayDiff;
              }
            } else {
              diffJson[key] = newJson[key];
            }
          } else {
            if (oldJson[key] !== newJson[key]) {
              diffJson[key] = newJson[key];
            }
          }
        }

        return diffJson;
      } catch (error) {
        console.error('Error parsing JSON:', error);
        return null; // Return null if parsing fails
      }
    },
    formatterConsultant(value) {
      let threeChars = /^[a-zA-Z]{3}$/;
      let twoChars = /^[a-zA-Z]{2}$/;

      if(threeChars.test(value)) return value;
      if(twoChars.test(value)) return value;
      
      return '';
    },
    getOrderNameAvailable() {
      if(this.formChanges > 0 && this.order.SubProjectId && this.order.OrderName) {
        ControllingService.getOrderNameAvailable({SubProjectId: this.order.SubProjectId, OrderId: this.order.OrderId, OrderName: this.order.OrderName})
        .then(res => {
          if(!res.data.OrderNameAvailable) {
            this.orderNameAvailable = false;
          } else {
            this.orderNameAvailable = null;
          }
        })
      }
    },
    getBudgetCategoryText(budgetCategoryId) {
      let cat = this.budgetCategories.find(e => e.value === budgetCategoryId);
      if(cat?.text) return cat.text;
      return null;
    },
    hasPermission(permission) {
      return AuthenticationService.hasPermission(permission);
    },
    onSubmit(event) {
      event.preventDefault();
      var isValid = this.validateOrder();
      if(!isValid) return;

      ControllingService.setOrder(this.order)
      .then(async (res) => {
        await this.deleteUploads();
        await this.uploadFiles(res.data.OrderId);
        this.$emit('close-reload');
      });
    },
    openUpload(uploadId) {
      if(!uploadId) return;
      UploadService.getUpload(uploadId)
      .then(res => {
        UploadService.openDataURI(res.data.DataURI);
      })
    },
    removeUpload(UploadId, index_orderYear, index_upload) {
      try {
        if(UploadId) {
          this.uploadsToBeDeleted.push(UploadId);
        }

        // create new uploads array to force rerender of upload inputs and its placeholders
        const oldUploads = this.order.OrderYears[index_orderYear].Uploads;
        this.order.OrderYears[index_orderYear].Uploads = [];
        for (let index = 0; index < 3; index++) {
          if(index != index_upload) {
            this.order.OrderYears[index_orderYear].Uploads.push(oldUploads[index]);
          } else {
            this.order.OrderYears[index_orderYear].Uploads.push(JSON.parse(JSON.stringify(UPLOAD_TEMPLATE)));
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    setOrderInactive() {
      ControllingService.setOrderInactive(this.order.OrderId)
      .then(() => {
        this.$emit('close-reload');
      })
    },
    transferFromPreviousYear() {
      if(this.transferOptions.files.value) {
        this.order.OrderYears[this.tabIndex].Uploads.forEach(u => {
          if(u.UploadId) this.uploadsToBeDeleted.push(u.UploadId);
        })
        this.order.OrderYears[this.tabIndex].Uploads = JSON.parse(JSON.stringify(this.order.OrderYears[this.tabIndex - 1].Uploads));
        this.order.OrderYears[this.tabIndex].Uploads.forEach(upload => {
          if(upload.UploadId && upload.Name && upload.OrderId) {
            UploadService.getUpload(upload.UploadId)
            .then(res => {
              upload.Year = this.order.OrderYears[this.tabIndex].Year;
              upload.DataURI = res.data.DataURI;
            });
          }
        });
      }
      if(this.transferOptions.budget.value) {
        this.order.OrderYears[this.tabIndex].PlannedSurveyInstanceNumber = this.order.OrderYears[this.tabIndex - 1].PlannedSurveyInstanceNumber;
        this.order.OrderYears[this.tabIndex].SurveyInstancePrice = this.order.OrderYears[this.tabIndex - 1].SurveyInstancePrice;
        this.order.OrderYears[this.tabIndex].Reserve = this.order.OrderYears[this.tabIndex - 1].Reserve;
        this.order.OrderYears[this.tabIndex].OverheadCharge = this.order.OrderYears[this.tabIndex - 1].OverheadCharge;
        this.order.OrderYears[this.tabIndex].MarketingCharge = this.order.OrderYears[this.tabIndex - 1].MarketingCharge;
      }
      if(this.transferOptions.costs.value) {
        this.order.OrderYears[this.tabIndex].ExternalCostsMsmTk = this.order.OrderYears[this.tabIndex - 1].ExternalCostsMsmTk;
        this.order.OrderYears[this.tabIndex].ExternalCostsAgency = this.order.OrderYears[this.tabIndex - 1].ExternalCostsAgency;
        this.order.OrderYears[this.tabIndex].ExternalCostsMdatiqs = this.order.OrderYears[this.tabIndex - 1].ExternalCostsMdatiqs;
        this.order.OrderYears[this.tabIndex].ExternalCostsOther = this.order.OrderYears[this.tabIndex - 1].ExternalCostsOther;
      }
      if(this.transferOptions.department.value) {
        this.order.OrderYears[this.tabIndex].BudgetField_percentage = this.order.OrderYears[this.tabIndex - 1].BudgetField_percentage;
        this.order.OrderYears[this.tabIndex].BudgetProjectManagement_percentage = this.order.OrderYears[this.tabIndex - 1].BudgetProjectManagement_percentage;
        this.order.OrderYears[this.tabIndex].BudgetQualityAssurance_percentage = this.order.OrderYears[this.tabIndex - 1].BudgetQualityAssurance_percentage;
      }
      if(this.transferOptions.address.value) {
        this.order.OrderYears[this.tabIndex].BillTo = this.order.OrderYears[this.tabIndex - 1].BillTo;
        this.order.OrderYears[this.tabIndex].BillingAddress = this.order.OrderYears[this.tabIndex - 1].BillingAddress;
        this.order.OrderYears[this.tabIndex].BillingCity = this.order.OrderYears[this.tabIndex - 1].BillingCity;
        this.order.OrderYears[this.tabIndex].BillingCountry = this.order.OrderYears[this.tabIndex - 1].BillingCountry;
        this.order.OrderYears[this.tabIndex].BillingPostalCode = this.order.OrderYears[this.tabIndex - 1].BillingPostalCode;
        this.order.OrderYears[this.tabIndex].BillingEmail = this.order.OrderYears[this.tabIndex - 1].BillingEmail;
      }
      this.calculateBudget(this.order.OrderYears[this.tabIndex]);
      this.calculateDepartmentBudgets(this.order.OrderYears[this.tabIndex]);
      this.calculateSurveyInstancePriceMdatiqs(this.order.OrderYears[this.tabIndex]);
      this.showTransferPopup = false;
    },
    async uploadFiles(orderId) {
      const uploadPromises = this.order.OrderYears.flatMap(oy =>
        oy.Uploads.map(u => {
          if (u.UploadFile) {
            return UploadService.uploadFile(orderId, oy.Year, u.UploadFile);
          } else if(u.DataURI) {
            return UploadService.setUpload(u);
          }
        })
      );
      await Promise.all(uploadPromises);
    },
    validateOrder() {
      var isValid = false;
      // remove empty strings
      for (var prop in this.order) {
        if (Object.prototype.hasOwnProperty.call(this.order, prop)) {
          if(this.order[prop] === '') this.order[prop] = null;
        }
      }
      // validate endDate
      isValid = this.validateEndDate();
      return isValid;
    },
    validateEndDate() {
      var isValid = false;
      var startDate = new Date(Date.parse(this.order.StartDate));
      var endDate = new Date(Date.parse(this.order.EndDate));
      if(startDate > endDate) {
        this.states.inputEndDate = false;
        isValid = false;
        //scroll up to input
        document.getElementById("order-edit-popup-card-body").scrollTo({top:0, behavior:"smooth"});
        this.inputEndDateInvalidMsg = "Das Enddatum muss hinter dem Startdatum liegen."
      } else {
        this.states.inputEndDate = true;
        isValid = true;
        this.inputEndDateInvalidMsg = null;
      }
      return isValid;
    }
  },
}
</script>

<style scoped>
.popup {
  width: 80vw;
  max-height: 80vh;
  position: fixed !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.25);
}
#order-edit-popup-form {
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
#order-edit-popup-card-body {
  overflow: auto;
}
#order-edit-popup-card-footer {
  background-color: rgba(0, 0, 0, 0) !important;
}
.upload-button-container {
  position: absolute;
  z-index: 10;
  transform: translate(0%,-111%);
  width: 90%;
  text-align: right;
}
.clickable {
  cursor: pointer;
}
.disabled-color {
  color: grey;
}
.toggle-collapse {
  display: flex;
  justify-content: space-between;
}
.rotate180 {
  transform: rotate(180deg);
}
.green {
  color: green;
}
.red {
  color: red;
}
.black {
  color: black;
}
.transfer-button {
  width: 34px;
  height: 32px;
  transition: width 200ms;
  display: flex;
  align-items: center;
}
.transfer-button:hover {
  width: 240px;
}
.transfer-button .transfer-span {
  display: none;
}
.transfer-button:hover .transfer-span {
  display: block ruby;
}
</style>