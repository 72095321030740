<template>
  <div v-if="this.params.value">
    <a 
      class="popup-trigger" 
      @click="showPopup()"
      v-b-tooltip.hover title="<220/=220 (geplant)"
    >{{ `${count_under_220}/${count_220} (${this.params.value[0].PlannedSurveyInstanceNumber})` }}</a>
  </div>
</template>

<script>
import EventBus from '../../services/EventBus';

export default {
  data() {
    return {
      count_220: 0,
      count_under_220: 0,
    }
  },
  mounted() {
    this.params.value?.forEach(e => {
      if(e?.SurveyInstanceCount) {
        if(e.SurveyInstanceStatusId === 220) this.count_220 = e.SurveyInstanceCount;
        else if (e.SurveyInstanceStatusId < 220) this.count_under_220 += e.SurveyInstanceCount
      }
    });
  },
  methods: {
    showPopup() {
      if(this.params.data) {
        EventBus.$emit("showSurveyInstanceCountPopup", this.params.data);
      } 
    }
  },
}
</script>

<style scoped>
.popup-trigger {
  text-decoration: underline;
  color: rgb(24, 29, 31);
}
.popup-trigger:hover {
  cursor: pointer;
  font-weight: bold;
}
</style>