import Vue from 'vue'
import App from './App.vue'
import { i18n, changeLocalization } from './i18n';
import EventBus from '@/services/EventBus.js';
import router from './router';
// import AuthenticationService from './services/AuthenticationService';
import VueCookies from 'vue-cookies';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faXmark, faDownload, faTrash, faPen, faBan, faBook, faLock, faLockOpen, faAngleUp, faBoxArchive, faCheck, faCopy, faCircleExclamation, faPlus } from '@fortawesome/free-solid-svg-icons'
import { faSquare, faSquareCheck } from '@fortawesome/free-regular-svg-icons'

/* add icons to the library */
library.add(faXmark,faDownload,faTrash,faPen,faBan,faBook,faLock,faLockOpen,faAngleUp,faSquare,faSquareCheck,faBoxArchive,faCheck,faCopy,faCircleExclamation,faPlus)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

window._ = require('lodash');

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(VueCookies);

Vue.component('font-awesome-icon', FontAwesomeIcon);

Vue.config.productionTip = process.env.NODE_ENV === 'production';

import {LicenseManager} from "ag-grid-enterprise";
LicenseManager.setLicenseKey(process.env.VUE_APP_AG_GRID);

let highestZIndex = 100, activeDraggable = null;
Vue.directive('draggable', {
  bind: function (el) {
    var startX, startY, initialMouseX, initialMouseY;

    // Give all components a different zIndex
    el.style.zIndex = highestZIndex++;
    el.style.position = 'absolute';

    function mousemove(e) {
      if ((e.clientX >= 10) && (e.clientX <= window.innerWidth - 10)) {
        var dx = e.clientX - initialMouseX;
        el.style.left = startX + dx + 'px';
      }
      if ((e.clientY >= 50) && (e.clientY <= window.innerHeight - 50)) {
        var dy = e.clientY - initialMouseY;
        el.style.top = startY + dy + 'px';

      }
      return false;
    }

    function mouseup() {
      document.body.classList.remove("disable-text-selection");
      document.removeEventListener('mousemove', mousemove);
      document.removeEventListener('mouseup', mouseup);
    }

    el.children[0].addEventListener('mousedown', function (e) {
      document.body.classList.add("disable-text-selection");

      startX = el.offsetLeft;
      startY = el.offsetTop;
      initialMouseX = e.clientX;
      initialMouseY = e.clientY;
      document.addEventListener('mousemove', mousemove);
      document.addEventListener('mouseup', mouseup);
      return false;
    });

    // Draggable elements will stay in visible part while resizing
    window.addEventListener("resize", function () {
      if (el.style.left != "") {
        if (window.innerWidth < parseInt(el.style.left) + 40) {
          el.style.left = (window.innerWidth - 60) + "px";
        }
        if ((window.innerHeight < parseInt(el.style.top) + 30) && window.innerHeight > 80) {
          el.style.top = (window.innerHeight - 50) + "px";
        }
      }
    });

    // Set component on top on focus change (click in element)
    el.addEventListener('mousedown', function (e) {
      // Only fire, if another component is clicked
      if (activeDraggable === el)
        return;
      activeDraggable = el;

      // We keep order by just increasing the zIndex
      el.style.zIndex = highestZIndex++;
    });
  },
});

// Set element 
EventBus.$on('set-topmost-element', (el) => {
  if ((el instanceof Element) && (el?.style))
    el.style.zIndex = highestZIndex++;
  else
    console.warn('Argument is no DOM element. Type: ', typeof el)
});



new Vue({
  render: h => h(App),
  router,
  i18n
}).$mount('#app')
